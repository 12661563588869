export const PROJECTS = [
    {
        id: 7,
        title: "Car Dealership",
        image: "/assets/images/car.png",
        type: "Capstone Project - Solo",
        role: "Software Engineer", 
        stack: { 
            one: "Python",
            two: "Django",
            three: "JavaScript",
            four: "Bootstrap", 
            five: "PostgreSQL",
            six: "Docker",
            seven: "Google Cloud"
        },
        description:"This is a full-stack Django application with a PostgreSQL database. The application is an inventory and sales management system. The user can view, create, update and delete cars and sales personnel.",
        challenges: "The biggest challenge was understanding the Django framework and how to integrate it with the PostgreSQL database. I had to consult the documentation and various tutorials to understand the framework and database. I also had to learn how to deploy the application to Google Cloud. I consulted the Google Cloud documentation and various tutorials to understand the deployment process.",   
        live: "https://car-app-wjuvn76ckq-uc.a.run.app/",
        repo: "https://github.com/Pia007/car_app"
    },
    {
        id: 0,
        title: "Colour Mi' Chic Salon",
        image: "/assets/images/cmc.png",
        type: "Freelance - Solo",
        role: "Software Engineer", 
        stack: { 
            one: "React",
            two: "Bootstrap",
            three: "JavaScript",
            four: "Axios",
            five: "Express",
            six: "Node.js",
            seven: "PostgreSQL",
        },
        description:"This application was built for a local business. The client requested that the website be unique, classy and pay homage to their origins. The UI was built with React and the backend was built with Node.js. In addition to the public facing interface, this web application features a secure custom dashboard that allows the client to update the data in real-time.",
        challenges: "The biggest opportunity was understanding the client's vision, experience, market and presenting it in a way that would be pleasing to the public and the client.",
        live: "https://colourmichic-cmc.com/",
    },
    {
        id: 1,
        title: "medView",
        image: "/assets/images/med.png",
        type: "Capstone Project - Solo",
        role: "Software Engineer", 
        stack: { 
            one: "React",
            two: "JavaScript",
            three: "Axios",
            four: "Java",
            five: "Spring Boot",
            six: "Maven",
            seven: "JPA",
            eight: "PostgreSQL"
        },
        description:"MedView Patient management system built with React and Spring Boot, that allows providers to register, login, add patients to their service, create view/update patient details, add and delete patient notes.",
        challenges: "Understanding JPA relational mappings and integrating ReactJs with Spring Boot were challenging. I reviewed the documentation to increased my understanding of JPA. I consulted various guides and tutorials to learn how to integrate ReactJs with Spring Boot.",
        video: "/assets/videos/medview.mp4",
        repo: "https://github.com/Pia007/medView"
    },
    {
        id: 2,
        title: "Viva Las Vegas",
        image: "/assets/images/vegas.png",
        type: "Capstone Project - Solo",
        role: "Software Engineer", 
        stack: { 
            one: "HTML5",
            two: "CSS3", 
            three: "Bootstrap",
            four: "JavaScript",
            five: "Axios",
            six: "Node.js",
            seven: "Express.js",
            eight: "Sequelize",
            nine: "PostgreSQL",
        },
        description:"Viva Las Vegas is a full-stack web application that allows users to post their favorite Las Vegas venues without the hassle of creating an account.",
        challenges: "I found implementing controllers and querying the database to be the most challenging. The PostgreSQL documentation and recalling OOP concepts increased my understanding.",
        live: "https://viva-las-vegas.herokuapp.com/",
        repo: "https://github.com/Pia007/viva_las_vegas"
    },
    {
        id: 3,
        title: "Host a Healthcare Hero",
        image: "/assets/images/hero.png",
        type: "Capstone Project - Solo",
        role: "Frontend Developer & Designer", 
        stack: { 
            one: "React",
            two: "Redux", 
            three: "Bootstrap",
            four: "CSS",
            five: "SPA",
            six: "Git",
            seven: "Netlify",
            eight: "SEO"
        },
        description:"I wanted to visualize a concept of how to give back to healthcare workers, at every level, who have sacrificed to care for us before and during the pandemic. The web application centers around a non-profit organization that utilizes volunteers and partnerships with local businesses to provide free health, wellness and personal care services to healthcare workers in Northern New Jersey.",
        challenges: "Some key challenges involved conditional rendering, leveraging global versus local state management and enhancing user experience. All of which were overcome by consulting documentation, like ReactJs.org, and a great deal of patience.",
        live: "https://hostahealtcarehero.netlify.app/",
        repo: "https://github.com/Pia007/Hero"
    },
    {
        id: 4,
        title: "JavaScript Algorithms",
        image: "/assets/images/algos.png",
        type: "Personal Project",
        role: "Frontend Developer & Designer", 
        stack: { 
            one: "HTML",
            two: "CSS", 
            three: "JavaScript",
            four: "Git",
            five: "Netlify",
            six: "SEO"
        },
        description:"As a new developer, grasping Javascript is sometimes frightening. As, I was learning and still am learning, I thought it would great to create and share an interactive collection of JavaScript algorithms that is useful for learning and practicing algorithms.",
        challenges: "Designing each dashboard to have its own uniqueness and functionlity was challenging. I used CSS variables and JavaScript documentation.",   
        live: "https://www.js-algorithms.com/",
        repo: "https://github.com/Pia007/Javascript-Algos"
    }
    // {
    //     id: 5,
    //     title: "Social Media App One",
    //     image: "/assets/images/sma-one.png",
    //     type: "Capstone Project - Team 1",
    //     role: "Frontend Developer - Authentication", 
    //     stack: { 
    //         one: "HTML",
    //         two: "CSS", 
    //         three: "JavaScript",
    //         four: "Git"
    //     },
    //     description:"This project was an internship at Reskill Americans. The project team was tasked with designing and building a full stack social media web application.",
    //     challenges: "I was responsible for with implementing the authentication pages, which were modals/pop-ups. I had to learn how to interpret the design and use vanilla JavaScript to create functional modals and process user inputs. Both of which were very challenging, at the time. Interacting with the design team was extremely helpful. And, collaborating with other team members, consulting mentors and documentation helped me to complete the tasks.",
    //     repo: "https://github.com/reskillamericans/SMA-Team-1"
    // },
    // {
    //     id: 6,
    //     title: "Jubilee",
    //     image: "/assets/images/jubilee.png",
    //     type: "Personal Project",
    //     role: "Frontend Developer & Designer", 
    //     stack: { 
    //         one: "HTML",
    //         two: "CSS", 
    //         three: "JavaScript",
    //         four: "Git" 
    //     },
    //     description:"This was my first personal project. I wanted to learn about the history of Juneteenth. I decided to create a simple application that would outline the history of the day.",
    //     challenges: "I had difficulty in using CSS to create a simple layout at that time. I had to dig deep and really learn the basics of CSS.",   
    //     live: "https://pia007.github.io/Jubilee/",
    //     repo: "https://github.com/Pia007/Jubilee"
    // },
];