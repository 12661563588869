import { Row, Col } from 'reactstrap';
import { SKILLS } from '../shared/skills';
import { SkillItem } from './Items';
import Fade from 'react-reveal/Fade';


const Skills = () => {
    const skills = SKILLS;

    const skillList = skills.map(skill => {
        return (
            <Col key={skill.id} xs={6} sm={4} md={3} lg={2} className='text-center mb-3'>
                <SkillItem 
                    logo={skill.logo}
                    alt={skill.name}
                    name={skill.name}
                    logoStyle={skill.logoStyle}
                />
            </Col>
        );
    });


    return (
        <div className='p-0 px-0'>
            <div className='text-center'>
                <Fade bottom delay={800}>
                    <h2 className='text-center info-header'>My Toolbox</h2>
                </Fade>
            </div>
            <Row className='my-3 p-0 row-content d-flex justify-content-around'>
                {skillList}
            </Row> 
        </div>
    );
}

export default Skills;