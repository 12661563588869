export const SKILLS = [
    {
        id: 0,
        name: "Java",
        logo: "/assets/logos/java.png",
        logoStyle: "skill-one"
    },
    {
        id: 1,
        name: "Spring",
        logo: "/assets/logos/spring.png",
        logoStyle: "skill-one"
    },
    {
        id: 2,
        name: "Spring Boot",
        logo: "/assets/logos/springboot.png",
        logoStyle: "skill-two"
    },
    {
        id: 5,
        name: "NodeJs",
        logo: "/assets/logos/node.png",
        logoStyle: "skill-one"
    },
    {
        id: 6,
        name: "Express",
        logo: "/assets/logos/express.png",
        logoStyle: "skill-one"
    },
    {
        id: 10,
        name: "Python",
        logo: "/assets/logos/python.png",
        logoStyle: "skill-one"
    },
    {
        id: 8,
        name: "Django",
        logo: "/assets/logos/django.png",
        logoStyle: "skill-one"
    },
    {
        id: 3,
        name: "SQL",
        logo: "/assets/logos/sql.png",
        logoStyle: "skill-one"
    },
    {
        id: 4,
        name: "PostgreSQL",
        logo: "/assets/logos/postgre.png",
        logoStyle: "skill-one"
    },
    {
        id: 7,
        name: "MongoDB",
        logo: "/assets/logos/mongodb.svg",
        logoStyle: "skill-one"
    },
    {
        id: 32,
        name: "Docker",
        logo: "/assets/logos/docker-logo.svg",
        logoStyle: "skill-one"
    },
    {
        id: 12,
        name: "React",
        logo: "/assets/logos/react.png",
        logoStyle: "skill-one"
    },
    {
        id: 32,
        name: "NextJS ",
        logo: "/assets/logos/nextjs-icon.svg",
        logoStyle: "skill-one"
    },
    {
        id: 13,
        name: "Redux",
        logo: "/assets/logos/red.svg",
        logoStyle: "skill-one"
    },
    {
        id: 14,
        name: "React Native",
        logo: "/assets/logos/rn.png",
        logoStyle: "skill-one"
    },
    {
        id: 15,
        name: "Axios",
        logo: "/assets/logos/axios.png",
        logoStyle: "skill-one"
    },
    {
        id: 16,
        name: "JavaScript",
        logo: "/assets/logos/javascript.png",
        logoStyle: "skill-one"
    },
    {
        id: 30,
        name: "TypeScript",
        logo: "/assets/logos/ts-logo.png",
        logoStyle: "skill-one"
    },
    // {
    //     id: 8,
    //     name: "Selenium",
    //     logo: "/assets/logos/selenium.png",
    //     logoStyle: "skill-one"
    // },
    // {
    //     id: 9,
    //     name: "Jest",
    //     logo: "/assets/logos/jest.png",
    //     logoStyle: "skill-one"
    // },
    // {
    //     id: 11,
    //     name: "RESTful APIs",
    //     logo: "/assets/logos/rest.svg",
    //     logoStyle: "skill-one"
    // },
    {
        id: 31,
        name: "Ant Design",
        logo: "/assets/logos/antDlogo.png",
        logoStyle: "skill-one"
    },
    {
        id: 19,
        name: "Bootstrap",
        logo: "/assets/logos/bootstrap.svg",
        logoStyle: "skill-one"
    },
    {
        id: 20,
        name: "Tailwind CSS",
        logo: "/assets/logos/tailwindcss.png",
        logoStyle: "skill-one"
    },
    // {
    //     id: 21,
    //     name: "jQuery",
    //     logo: "/assets/logos/jq.png",
    //     logoStyle: "skill-one"
    // },
    // {
    //     id: 22,
    //     name: "Git",
    //     logo: "/assets/logos/git.png",
    //     logoStyle: "skill-one"
    // },
    // {
    //     id: 23,
    //     name: "Flask",
    //     logo: "/assets/logos/flask-logo.png",
    //     logoStyle: "skill-two"
    // },
    {
        id: 24,
        name: "AWS",
        logo: "/assets/logos/aws.png",
        logoStyle: "skill-one"
    },
    {
        id: 25,
        name: "Azure",
        logo: "/assets/logos/ms-azure.png",
        logoStyle: "skill-one"
    },
    {
        id: 26,
        name: "Google Cloud",
        logo: "/assets/logos/gcp1.png",
        logoStyle: "skill-one"
    },
    // {
    //     id: 27,
    //     name: "Figma",
    //     logo: "/assets/logos/figma.png",
    //     logoStyle: "skill-one"
    // },
    // {
    //     id: 28,
    //     name: "Netlify",
    //     logo: "/assets/logos/netlify.png",
    //     logoStyle: "skill-one"
    // },
    {
        id: 29,
        name: "Heroku",
        logo: "/assets/logos/heroku.png",
        logoStyle: "skill-one"
    },
];